.detCard {

  form > button {
    width: 100%
  }

  select {
    width: 100%
  }
}
